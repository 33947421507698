import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { create } from 'ackee-tracker';
import './locales/Styles.css';
import PublicNav from './components/pages/landingPage/js/PublicNav.js';
import PrivateNav from './components/pages/mainPage/js/PrivateNav.js';
import HreflangHandler from './components/pages/shared/HreflangHandler.js';

const LightCursor = '/light_cursor.png';

// Lazy Loading pour les composants lourds
const LandingPage = lazy(() => import('./components/pages/landingPage/js/LandingPage.js'));
const Pricing = lazy(() => import('./components/pages/pricingPage/js/pricingPage.js'));
const FAQPage = lazy(() => import('./components/pages/FAQPage/js/FAQPage.js'));
const FeaturesPage = lazy(() => import('./components/pages/featuresPage/js/featuresPage.js'));
const ContactPage = lazy(() => import('./components/pages/contactPage/js/contactPage.js'));
const PrivacyPage = lazy(() => import('./components/pages/privacyPage/js/PrivacyPage.js'));
const AboutUs = lazy(() => import('./components/pages/aboutusPage/js/AboutUsPage.js'));
const Dashboard = lazy(() => import('./components/pages/mainPage/js/Dashboard.js'));
const EbayAuthSuccess = lazy(() => import('./components/authEbay/EbayAuthSuccess.js'));

// REMETTRE LES IMPORTS SI NÉCESSAIRE
// const LoginPage = lazy(() => import('./components/pages/loginPage/js/loginPage.js'));
// const RegisterPage = lazy(() => import('./components/pages/registerPage/js/registerPage.js'));

// Composant pour les routes privées
function PrivateRoute({ children }) {
  const token = localStorage.getItem('accessToken');
  return token ? children : <Navigate to="/login" />;
}


function Layout({ isLoggedIn, handleLogout }) {
  const location = useLocation();
  const publicRoutes = ["/", "/pricing", "/login", "/register", "/faqs", "/privacy", "/features", "/contact", "/aboutus"];
  const isPublicRoute = publicRoutes.includes(location.pathname);

  // Suivi Ackee
  useEffect(() => {
    //console.log("Ackee tracker setup started");

    // Crée une instance Ackee
    const ackeeInstance = create('https://stats01ackeeprime.tradylist.com', {
      detailed: true, // Pour des statistiques plus détaillées
    });

    // Enregistre la visite pour chaque changement de route
    const tracking = ackeeInstance.record(process.env.REACT_APP_ACKEE_DOMAIN_ID);

    //console.log("Ackee tracking initialized");

    // Arrête l'enregistrement si le composant est démonté
    return () => {
      if (tracking && typeof tracking.stop === 'function') {
        tracking.stop();
        //console.log("Ackee tracking stopped");
      } else {
        //console.warn("Ackee tracking not stopped: tracking object is invalid");
      }
    };
  }, [location]);

  return isPublicRoute || !isLoggedIn ? <PublicNav /> : <PrivateNav onLogout={handleLogout} />;
}


// Composant pour la navigation
//function Layout({ isLoggedIn, handleLogout }) {
//  const location = useLocation();
//  const publicRoutes = ["/", "/pricing", "/login", "/register", "/faqs", "/privacy", "/features", "/contact", "/aboutus"];
//  const isPublicRoute = publicRoutes.includes(location.pathname);

  // Suivi Ackee
//  useEffect(() => {
//    console.log("Ackee tracker setup started");

    // Crée une instance Ackee
//    const ackeeInstance = create('https://stats01ackeeprime.tradylist.com', {
//      detailed: true, // Pour des statistiques plus détaillées
//    });

    // Enregistre la visite pour chaque changement de route
//    const stopRecording = ackeeInstance.record(process.env.REACT_APP_ACKEE_DOMAIN_ID);

//    console.log("Ackee tracking initialized");

    // Arrête l'enregistrement si le composant est démonté
//    return () => {
//      if (stopRecording) {
//        stopRecording();
//        console.log("Ackee tracking stopped");
//      }
//   };
//  }, [location]);

//  return isPublicRoute || !isLoggedIn ? <PublicNav /> : <PrivateNav onLogout={handleLogout} />;
//}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = async () => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
    }
    localStorage.removeItem('accessToken');
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <div style={{ cursor: `url(${LightCursor}), auto` }}>
        {/* Gestion des balises hreflang */}
        <HreflangHandler />

        <Layout isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
        <Suspense fallback={<div>Chargement...</div>}>
          <Routes>
            {/* Routes publiques */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/faqs" element={<FAQPage />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/aboutus" element={<AboutUs />} />

            {/* Routes privées */}
            <Route path="/dashboard" element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />

            {/* Route spécifique */}
            <Route path="/ebay/auth-success" element={<EbayAuthSuccess />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
