import React from 'react';

function PrivateNav({ onLogout }) { // Utilise `onLogout` en prop
  return (
    <nav className="private-navbar">
      <ul>
        <li><a href="#dashboard">Tableau de bord</a></li>
        <li><a href="#settings">Paramètres</a></li>
        <li><a href="#logout" onClick={onLogout}>Déconnexion</a></li> {/* Appelle `onLogout` */}
      </ul>
    </nav>
  );
}

export default PrivateNav;