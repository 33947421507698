import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavbarButton = ({ label, to, className = '', newTab = false, onClick }) => (
  <Link
    to={to}
    className={`nav-button ${className}`}
    target={newTab ? '_blank' : undefined}
    onClick={onClick}
  >
    {label}
  </Link>
);

const PublicNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);
  const changeLanguage = (lng) => i18n.changeLanguage(lng);

  return (
    <>
      {/* Barre de navigation principale */}
      <nav
        className="navbar"
        role="navigation"
        aria-label="Navigation principale"
      >
        <div className="navbar-container">
          {/* Logo */}
          <Link
            to="/"
            className="navbar-logo-brand"
            aria-label="Retour à l'accueil"
          >
          <Link to="/" className="navbar-logo-brand">
            <img src="/icons/logo_tradylist1.png" alt="TradyList - Gestion et diffusion d'annonces" />
          </Link>


          </Link>

          {/* Bouton toggle pour mobile */}
          <button
            className={`menu-toggle ${isOpen ? 'open' : ''}`}
            onClick={toggleMenu}
            aria-expanded={isOpen}
            aria-controls="navbar-links"
            aria-label={isOpen ? 'Fermer le menu' : 'Ouvrir le menu'}
          >
            <div></div>
            <div></div>
            <div></div>
          </button>

          {/* Liens de navigation */}
          <ul
            id="navbar-links"
            className={`navbar-links ${isOpen ? 'show' : ''}`}
          >
            <li className="navbar-item">
              <NavbarButton
                label={t('navbar.features')}
                to="/features"
                onClick={closeMenu}
              />
            </li>
            <li className="navbar-item">
              <NavbarButton
                label={t('navbar.pricing')}
                to="/pricing"
                onClick={closeMenu}
              />
            </li>
            <li className="navbar-item">
              <NavbarButton
                label={t('navbar.faqs')}
                to="/faqs"
                onClick={closeMenu}
              />
            </li>
            <li className="navbar-item">
              <NavbarButton
                label={t('navbar.contact')}
                to="/contact"
                onClick={closeMenu}
              />
            </li>

            {/* Language Switcher */}
            <div className="language-switcher" aria-label="Changer de langue">
              <button onClick={() => changeLanguage('en')} aria-label="English">
                EN
              </button>
              <button onClick={() => changeLanguage('fr')} aria-label="Français">
                FR
              </button>
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default PublicNav;
