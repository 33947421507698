// src/components/shared/HreflangHandler.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const HreflangHandler = () => {
  const location = useLocation();
  const supportedLanguages = ['en', 'fr']; // Langues prises en charge
  const defaultLanguage = 'en'; // Langue par défaut

  useEffect(() => {
    const pathLang = location.pathname.split('/')[1];
    const currentLang = supportedLanguages.includes(pathLang) ? pathLang : defaultLanguage;

    // Supprime les anciennes balises hreflang
    document.querySelectorAll('link[rel="alternate"]').forEach((tag) => tag.remove());

    // Ajoute les nouvelles balises hreflang
    supportedLanguages.forEach((lang) => {
      const hreflangTag = document.createElement('link');
      hreflangTag.rel = 'alternate';
      hreflangTag.hreflang = lang;
      hreflangTag.href = `${window.location.origin}/${lang === defaultLanguage ? '' : lang}`;
      document.head.appendChild(hreflangTag);
    });

    // Ajoute la balise x-default
    const xDefaultTag = document.createElement('link');
    xDefaultTag.rel = 'alternate';
    xDefaultTag.hreflang = 'x-default';
    xDefaultTag.href = window.location.origin;
    document.head.appendChild(xDefaultTag);
  }, [location]);

  return null; // Ce composant n'affiche rien
};

export default HreflangHandler;
